<template>
  <Item :item="item" :parent="parent" class="inline-flex items-center">
    <button
      type="button"
      class="lg:hidden ml-10 flex-center"
      @click="navIsVisible = !navIsVisible"
    >
      <svg
        class="nav-hamburger"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        >
          <path d="M2.25 18.003h19.5M2.25 12.003h19.5M2.25 6.003h19.5" />
        </g>
      </svg>
    </button>

    <nav
      class="lg:flex nav-close"
      :class="navIsVisible ? 'nav-cover' : 'hidden'"
    >
      <button
        type="button"
        class="lg:hidden self-end my-4 mx-6 text-3xl"
        @click="navIsVisible = !navIsVisible"
      >
        ✕
      </button>

      <ul class="space-y-8 lg:space-y-0 lg:space-x-6 nav-items">
        <li v-for="{ uid, label, slug } in internalLinks" :key="uid">
          <InternalLink
            :label="label"
            :target-id="slug"
            class="nav-item-link"
            :click-event="
              () => {
                navIsVisible = false
              }
            "
          >
            {{ label }}
          </InternalLink>
        </li>
      </ul>

      <ul
        v-if="externalLinks.length"
        class="pl-6 space-y-8 lg:space-y-0 lg:space-x-6 nav-items"
      >
        <li v-for="({ url, label, style }, idx) in externalLinks" :key="idx">
          <a
            :href="url"
            target="_blank"
            class=""
            :class="{
              'public-btn py-2 px-4': style.startsWith('btn'),
              'public-btn--outline': style === 'btn-outline',
              'public-btn--rounded': btnShape === 'rounded',
              'public-btn--rounded-full': btnShape === 'roundedFull',
            }"
            >{{ label }}</a
          >
        </li>
      </ul>
    </nav>
  </Item>
</template>

<script>
import { get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'

export default {
  name: 'Navigation',

  components: {
    Item: () => import('@/components/Item'),
    InternalLink: () => import('@/components/InternalLink'),
  },

  mixins: [itemMixin],

  data: () => ({
    navIsVisible: false,
  }),

  computed: {
    btnShape: get('page/btnShape'),

    internalLinks() {
      return this.item.settings.items.filter(item => item.uid)
    },

    externalLinks() {
      return this.item.settings.items.filter(item => item.url)
    },
  },
}
</script>

<style>
.nav-items {
  @apply inline-flex
  lg:flex
  flex-col
  lg:flex-row lg:justify-end
  items-center
  m-auto
  text-xl
  lg:text-base
  text-center;
}

.nav-item-link,
.nav-close {
  color: var(--contrastColor);
}

.nav-cover {
  @apply flex fixed top-0 right-0 bottom-0 left-0 flex-col z-1000;
  background: var(--bgColor, #fff);
}

@screen lg {
  .nav-cover {
    @apply relative top-auto right-auto bottom-auto left-auto z-0 flex-row bg-transparent;
  }
}

.nav-hamburger {
  @apply w-8 h-8;
  g {
    stroke: var(--textColor);
  }
}
</style>
